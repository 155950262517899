import { ZonedDate } from "@flicket/utils";
import { i18n } from "~lib";

export function useCompetitionClosesText(endDate: string) {
  const endDateFormatted = ZonedDate.format(endDate, "datetime", {
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });

  return `Competition closes ${endDateFormatted}`;
}
